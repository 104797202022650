<template>
	<div v-if="!$globalLoading">
		<!-- Back Button -->
		<v-toolbar flat class="tw-sticky tw-top-0 tw-z-8 tw--ms-1">
			<button
				v-if="$currentUser.isAdmin"
				class="tw-mr-3"
				@click="onClickBack"
			>
				<v-avatar size="40" class="tw-bg-ee">
					<v-icon color="black">mdi-chevron-left</v-icon>
				</v-avatar>
			</button>
			<v-toolbar-title>
				<span
					class="tw-font-semibold tw-text-black tw-font-pop tw-text-2xl"
				>
					<template v-if="$currentUser.isAdmin">
						<template>
							{{
								isUpdate
									? $t('actions.update')
									: isApprove
									? 'Approve / Reject'
									: ''
							}}
							{{ name }}
						</template>
						<template>{{ $t('registration-request') }}</template>
					</template>
					<template v-else> {{ $t('establishment-file') }} </template>
				</span>
			</v-toolbar-title>

			<v-spacer />

			<template v-if="!$currentUser.isAdmin">
				<!-- CANCEL -->
				<base-button
					v-if="$route.path.includes('/update')"
					:attrs="{ text: true }"
					:to="
						$currentUser.isSupplier
							? '/supplier/establishment'
							: '/establishment'
					"
					text
					class="tw-me-3"
				>
					{{ $t('actions.cancel') }}
				</base-button>

				<!-- GOTO UPDATE BTN -->
				<!-- v-else-if="$currentUser.isOwner" -->
				<base-button
					v-else-if="$currentUser.isAdmin"
					:to="
						$currentUser.isSupplier
							? '/supplier/establishment/update'
							: '/establishment/update'
					"
				>
					{{ $t('actions.update') }}
				</base-button>
			</template>

			<base-button
				v-if="isUpdate"
				:loading="isUpdating"
				@click="onClickCreateUpdate"
			>
				<template v-if="$currentUser.isAdmin">
					{{ $t('actions.update') }}
				</template>
				<template v-else> {{ $t('actions.submit') }} </template>
			</base-button>

			<base-button
				v-if="isCreate"
				:loading="isCreating"
				@click="onClickCreateUpdate"
			>
				{{ $t('actions.submit') }}
			</base-button>
		</v-toolbar>

		<v-container fluid>
			<h3
				class="tw-font-semibold tw-text-black tw-font-pop tw-text-19px tw-mt-4 lg:tw-mt-7 tw-mb-6 lg:tw-mb-12"
			>
				{{ $t('establishment.companyInfo') }}
			</h3>

			<v-form
				v-if="!loading"
				v-model="isFormValid"
				:readonly="isReadonly"
				ref="formRef"
				lazy-validation
				@submit.prevent="onSubmit"
			>
				<v-row>
					<v-col cols="12" :md="isSupplier ? 6 : 12">
						<div>
							<div
								class="tw-flex tw-items-center tw-space-s-3.5 tw-mb-4"
							>
								<v-icon color="#D5D5D5"
									>mdi-office-building-outline</v-icon
								>
								<span class="tw-font-medium tw-text-black">
									{{ $t('establishment.companyName') }}
								</span>
							</div>
							<v-text-field
								v-model="establishment.company.name"
								:rules="[rules.required($t('name'))]"
								:placeholder="$t('signUp.companyNamePlaceholder')"
								:height="$vuetify.breakpoint.lgAndUp ? '60px' : ''"
								outlined
								name="company.name"
								hide-details="auto"
								class="custom-outlined placeholder-padding tw-rounded-md"
							/>
						</div>
					</v-col>
					<v-col
						v-if="isSupplier"
						md="6"
						cols="12"
						class="tw-flex tw-justify-end tw-items-end"
					>
						<v-card outlined rounded="md" class="tw-flex-1 tw-border-f0f">
							<v-card-text>
								<v-checkbox
									v-model="establishment.supplier.canBuyerSee"
									:label="$t('show-data-to-buyer')"
									class="pa-0 ma-0"
									hide-details="auto"
								/>
							</v-card-text>
						</v-card>
					</v-col>

					<v-col cols="12" md="6">
						<div>
							<div
								class="tw-flex tw-items-center tw-space-s-3.5 tw-mb-4"
							>
								<v-icon color="#D5D5D5"
									>mdi-file-document-outline</v-icon
								>
								<span class="tw-font-medium tw-text-black">{{
									$t('signUp.commercialRegistrationNo')
								}}</span>
							</div>
							<v-text-field
								v-model="establishment.company.registrationNumber"
								:height="$vuetify.breakpoint.lgAndUp ? '60px' : ''"
								:rules="[rules.required($t('number'))]"
								:placeholder="
									$t('signUp.commercialRegistrationNoPlaceholder')
								"
								class="custom-outlined placeholder-padding tw-rounded-md"
								outlined
								hide-details="auto"
								name="company.registrationNumber"
							/>
						</div>
					</v-col>
					<v-col cols="12" md="6">
						<div>
							<div
								class="tw-flex tw-items-center tw-space-s-3.5 tw-mb-4"
							>
								<v-icon color="#D5D5D5">mdi-calendar-outline</v-icon>
								<span class="tw-font-medium tw-text-black">
									{{ $t('signUp.recordExpirationDate') }}
								</span>
							</div>
							<BaseDatePicker
								v-model="establishment.company.recordExpDate"
								:readonly="isReadonly"
								:rules="[rules.required($t('date'))]"
								:placeholder="
									$t('signUp.recordExpirationDatePlaceholder')
								"
								input-class="custom-outlined placeholder-padding tw-rounded-md"
							/>
						</div>
					</v-col>

					<v-col v-if="isCreate || isUpdate" cols="12">
						<BaseUpload
							v-model="establishment.company.proofOfRegistration"
							title="signUp.companyAttachment"
							subtitle="signUp.companyAttachmentDescreption"
							@input="showCompanyAttachError = false"
						/>
						<span
							v-if="showCompanyAttachError"
							class="red--text tw-ms-2 tw-text-xs"
						>
							{{ $t('comRegAttReq') }}
						</span>
					</v-col>

					<v-col cols="12">
						<div>
							<div
								class="tw-flex tw-items-center tw-space-s-3.5 tw-mb-4"
							>
								<v-icon color="#D5D5D5">mdi-store-outline</v-icon>
								<span class="tw-font-medium tw-text-black">{{
									$t('signUp.storeName')
								}}</span>
							</div>
							<v-text-field
								v-model="establishment.company.storeName"
								:placeholder="$t('signUp.storeNamePlaceholder')"
								:height="$vuetify.breakpoint.lgAndUp ? '60px' : ''"
								outlined
								hide-details="auto"
								class="custom-outlined placeholder-padding tw-rounded-md"
							/>
						</div>
					</v-col>
					<v-col cols="12" md="6">
						<div>
							<div
								class="tw-flex tw-items-center tw-space-s-3.5 tw-mb-4"
							>
								<v-icon color="#D5D5D5">mdi-phone</v-icon>
								<span class="tw-font-medium tw-text-black">{{
									$t('signUp.phoneNumber')
								}}</span>
							</div>
							<v-text-field
								outlined
								type="tel"
								:height="$vuetify.breakpoint.lgAndUp ? '60px' : ''"
								:rules="[rules.required($t('phoneNumber'))]"
								v-model="establishment.company.phone"
								class="custom-outlined placeholder-padding tw-rounded-md"
								:placeholder="$t('signUp.phoneNumberPlaceholder')"
								hide-details="auto"
							/>
						</div>
					</v-col>
					<v-col cols="12" md="6">
						<div>
							<div
								class="tw-flex tw-items-center tw-space-s-3.5 tw-mb-4"
							>
								<v-icon color="#D5D5D5">mdi-email</v-icon>
								<span class="tw-font-medium tw-text-black">
									{{ $t('signUp.companyEmail') }}
								</span>
							</div>
							<v-text-field
								outlined
								:height="$vuetify.breakpoint.lgAndUp ? '60px' : ''"
								:rules="[rules.required($t('email')), rules.email]"
								type="email"
								v-model="establishment.company.email"
								class="custom-outlined placeholder-padding tw-rounded-md"
								:placeholder="$t('signUp.companyEmailPlaceholder')"
								hide-details="auto"
							/>
						</div>
					</v-col>
					<v-col cols="12" md="6">
						<div>
							<div
								class="tw-flex tw-items-center tw-space-s-3.5 tw-mb-4"
							>
								<v-icon color="#D5D5D5">mdi-briefcase-outline</v-icon>
								<span class="tw-font-medium tw-text-black">
									{{ $t('signUp.mainActivity') }}
								</span>
							</div>
							<!-- <v-select
							v-model="establishment.company.mainActivity"
							:placeholder="$t('signUp.mainActivityPlaceholder')"
							:rules="[rules.required($t('signUp.mainActivity'))]"
							:height="$vuetify.breakpoint.lgAndUp ? '60px' : ''"
							:items="activity"
							item-text="name"
							item-value="id"
							outlined
							hide-details="auto"
							class="custom-outlined placeholder-padding tw-rounded-md"
						/> -->
							<v-select
								v-model="establishment.company.mainActivity"
								:items="activity"
								:rules="[rules.required('Main Activity')]"
								:height="$vuetify.breakpoint.lgAndUp ? '60px' : ''"
								:placeholder="$t('signUp.mainActivityPlaceholder')"
								:label="$t('signUp.mainActivity')"
								outlined
								single-line
								return-object
								item-value="id"
								item-text="name"
								hide-details="auto"
								class="custom-outlined placeholder-padding tw-rounded-md"
							/>
						</div>
					</v-col>
					<v-col cols="12" md="6">
						<div>
							<div
								class="tw-flex tw-items-center tw-space-s-3.5 tw-mb-4"
							>
								<v-icon color="#D5D5D5">mdi-briefcase-outline</v-icon>
								<span class="tw-font-medium tw-text-black">{{
									$t('signUp.subActivity')
								}}</span>
							</div>

							<v-select
								v-model="establishment.company.subActivity"
								:items="subActivity"
								:label="$t('signUp.subActivity')"
								:placeholder="$t('signUp.subActivityPlaceholder')"
								:rules="[rules.required('Sub Activity')]"
								:height="$vuetify.breakpoint.lgAndUp ? '60px' : ''"
								outlined
								single-line
								return-object
								item-value="id"
								item-text="name"
								hide-details="auto"
								class="custom-outlined placeholder-padding tw-rounded-md"
							></v-select>
							<!-- <v-text-field
							outlined
							:height="$vuetify.breakpoint.lgAndUp ? '60px' : ''"
							class="custom-outlined placeholder-padding tw-rounded-md"
							:placeholder="$t('signUp.subActivityPlaceholder')"
							hide-details="auto"
							v-model="establishment.company.subActivity"
						/> -->
						</div>
					</v-col>
				</v-row>

				<h3
					class="tw-font-semibold tw-font-pop tw-text-19px tw-mt-4 lg:tw-mt-7 tw-mb-6 lg:tw-mb-12"
				>
					{{ $t('establishment.bankAccInfo') }}
				</h3>
				<v-row>
					<v-col cols="12" md="6">
						<div>
							<div
								class="tw-flex tw-items-center tw-space-s-3.5 tw-mb-4"
							>
								<v-icon color="#D5D5D5">mdi-account-outline</v-icon>
								<span class="tw-font-medium tw-text-black">{{
									$t('signUp.accountName')
								}}</span>
							</div>
							<v-text-field
								outlined
								:rules="[rules.required($t('signUp.accountName'))]"
								v-model="establishment.bank.accountName"
								class="custom-outlined placeholder-padding tw-rounded-md"
								:placeholder="$t('signUp.accountNamePlaceholder')"
								hide-details="auto"
							/>
						</div>
					</v-col>

					<v-col cols="12" md="6">
						<div>
							<div
								class="tw-flex tw-items-center tw-space-s-3.5 tw-mb-4"
							>
								<v-icon color="#D5D5D5">mdi-bank-outline</v-icon>
								<span class="tw-font-medium tw-text-black">{{
									$t('signUp.bankName')
								}}</span>
							</div>

							<v-autocomplete
								v-model="establishment.bank.bankId"
								:items="$banks"
								:placeholder="$t('signUp.bankNamePlaceholder')"
								:rules="[rules.required($t('signUp.bankName'))]"
								outlined
								item-value="id"
								item-text="bankName"
								hide-details="auto"
								class="custom-outlined placeholder-padding tw-rounded-md"
							/>
						</div>
					</v-col>

					<v-col cols="12" md="6">
						<div>
							<div
								class="tw-flex tw-items-center tw-space-s-3.5 tw-mb-4"
							>
								<v-icon color="#D5D5D5">mdi-card-text-outline</v-icon>
								<span class="tw-font-medium tw-text-black">
									{{ $t('signUp.accountNumber') }}
								</span>
							</div>
							<v-text-field
								outlined
								:rules="[rules.required($t('signUp.accountNumber'))]"
								v-model="establishment.bank.accountNumber"
								class="custom-outlined placeholder-padding tw-rounded-md"
								:placeholder="$t('signUp.accountNumberPlaceholder')"
								hide-details="auto"
							/>
						</div>
					</v-col>
					<v-col v-if="isCreate || isUpdate" cols="12" md="6">
						<div class="tw-flex tw-items-center tw-space-s-3.5 tw-mb-4">
							<v-icon color="#D5D5D5">mdi-check</v-icon>
							<span class="tw-font-medium tw-text-black">
								{{ $t('is-owner') }}
							</span>
						</div>
						<v-btn-toggle
							v-model="establishment.supplier.isOwner"
							mandatory
							class="tw-w-1/2"
						>
							<!-- :class="
							isReadonly ? 'tw-pointer-events-none tw-select-none' : ''
						" -->
							<v-btn
								:value="true"
								:color="
									establishment.supplier.isOwner
										? '#F2CA51'
										: '#D0D0D0'
								"
								block
							>
								<span
									class="tw-text-base tw-text-white tw-normal-case"
									>{{ $t('yes') }}</span
								>
							</v-btn>
							<v-btn
								:value="false"
								:color="
									!establishment.supplier.isOwner
										? '#F2CA51'
										: '#D0D0D0'
								"
								block
							>
								<span
									class="tw-text-base tw-text-white tw-normal-case"
									>{{ $t('no') }}</span
								>
							</v-btn>
						</v-btn-toggle>
					</v-col>

					<v-col v-if="isCreate || isUpdate" cols="12">
						<BaseUpload
							v-model="establishment.bank.proofOfAccount"
							title="signUp.bankAttachment"
							subtitle="signUp.attachmentSizeHint"
							@input="showBankAttachError = false"
						/>
						<span
							v-if="showBankAttachError"
							class="red--text tw-ms-2 tw-text-xs"
						>
							{{ $t('bank-attach-required') }}
						</span>
					</v-col>

					<v-col cols="12">
						<div>
							<div
								class="tw-flex tw-items-center tw-space-s-3.5 tw-mb-4"
							>
								<v-icon dark>mdi-home</v-icon>
								<span class="tw-font-medium tw-text-black">
									{{ $t('signUp.companyRepresentativeName') }}
								</span>
							</div>
							<v-text-field
								outlined
								:rules="[rules.required($t('name'))]"
								v-model="establishment.supplier.representativeName"
								class="custom-outlined placeholder-padding tw-rounded-md"
								:placeholder="$t('signUp.companyRepresentativeName')"
								hide-details="auto"
							/>
						</div>
					</v-col>

					<v-col v-if="isCreate || isUpdate" cols="12">
						<BaseUpload
							v-model="establishment.supplier.representativeAttachment"
							title="signUp.representativeAuthorizationLetter"
							subtitle="signUp.attachmentSizeHint"
							@input="showRepresentativeAttachment = false"
						/>

						<span
							v-if="showRepresentativeAttachment"
							class="red--text tw-ms-2 tw-text-xs"
						>
							{{ $t('repr-auth-required') }}
						</span>
					</v-col>
				</v-row>
				<!-- national address -->
				<h3
					class="tw-font-semibold tw-font-pop tw-text-19px tw-mt-4 lg:tw-mt-7 tw-mb-6 lg:tw-mb-12"
				>
					{{ $t('establishment.adress') }}
				</h3>
				<v-row>
					<v-col cols="12" md="6">
						<div>
							<div
								class="tw-flex tw-items-center tw-space-s-3.5 tw-mb-4"
							>
								<v-icon color="#D5D5D5">mdi-map-marker-outline</v-icon>
								<span class="tw-font-medium tw-text-black">
									{{ $t('signUp.buildingAddress') }}
								</span>
							</div>
							<v-text-field
								outlined
								:rules="[rules.required($t('signUp.buildingAddress'))]"
								v-model="establishment.address.buildingNumber"
								class="custom-outlined placeholder-padding tw-rounded-md"
								tw-rounded-md
								:placeholder="$t('signUp.buildingAddressPlaceholder')"
								hide-details="auto"
							/>
						</div>
					</v-col>

					<v-col cols="12" md="6">
						<div>
							<div
								class="tw-flex tw-items-center tw-space-s-3.5 tw-mb-4"
							>
								<v-icon color="#D5D5D5">mdi-map-marker-outline</v-icon>
								<span class="tw-font-medium tw-text-black">{{
									$t('signUp.streetName')
								}}</span>
							</div>
							<v-text-field
								v-model="establishment.address.streetName"
								:rules="[rules.required($t('signUp.streetName'))]"
								:placeholder="$t('signUp.streetNamePlaceholder')"
								outlined
								tw-rounded-md
								hide-details="auto"
								class="custom-outlined placeholder-padding tw-rounded-md"
							/>
						</div>
					</v-col>
					<v-col cols="12" md="6">
						<div>
							<div
								class="tw-flex tw-items-center tw-space-s-3.5 tw-mb-4"
							>
								<v-icon color="#D5D5D5">mdi-map-marker-outline</v-icon>
								<span class="tw-font-medium tw-text-black">{{
									$t('signUp.neighbourhoodName')
								}}</span>
							</div>
							<v-text-field
								v-model="establishment.address.neighborhoodName"
								:rules="[
									rules.required($t('signUp.neighbourhoodName')),
								]"
								:placeholder="$t('signUp.neighbourhoodNamePlaceholder')"
								outlined
								tw-rounded-md
								hide-details="auto"
								class="custom-outlined placeholder-padding tw-rounded-md"
							/>
						</div>
					</v-col>

					<v-col cols="12" md="6">
						<div>
							<div
								class="tw-flex tw-items-center tw-space-s-3.5 tw-mb-4"
							>
								<v-icon color="#D5D5D5">mdi-map-marker-outline</v-icon>
								<span class="tw-font-medium tw-text-black">{{
									$t('signUp.city')
								}}</span>
							</div>
							<v-text-field
								outlined
								v-model="establishment.address.city"
								class="custom-outlined placeholder-padding tw-rounded-md"
								:placeholder="$t('signUp.cityPlaceholder')"
								hide-details="auto"
							/>
						</div>
					</v-col>
					<v-col cols="12" md="6">
						<div>
							<div
								class="tw-flex tw-items-center tw-space-s-3.5 tw-mb-4"
							>
								<v-icon color="#D5D5D5">mdi-map</v-icon>
								<span class="tw-font-medium tw-text-black">{{
									$t('signUp.postalCode')
								}}</span>
							</div>
							<v-text-field
								v-model="establishment.address.postalCode"
								:rules="[rules.required($t('signUp.postalCode'))]"
								:placeholder="$t('signUp.postalCodePlaceholder')"
								outlined
								hide-details="auto"
								class="custom-outlined placeholder-padding tw-rounded-md"
							/>
						</div>
					</v-col>
					<v-col cols="12" md="6">
						<div>
							<div
								class="tw-flex tw-items-center tw-space-s-3.5 tw-mb-4"
							>
								<v-icon color="#D5D5D5"
									>mdi-office-building-outline</v-icon
								>
								<span class="tw-font-medium tw-text-black">{{
									$t('signUp.unitNumber')
								}}</span>
							</div>
							<v-text-field
								v-model="establishment.address.unitNumber"
								:rules="[rules.required($t('signUp.unitNumber'))]"
								:placeholder="$t('signUp.unitNumberPlaceholder')"
								outlined
								hide-details="auto"
								class="custom-outlined placeholder-padding tw-rounded-md"
							/>
						</div>
					</v-col>
					<v-col cols="12" md="6">
						<div>
							<div
								class="tw-flex tw-items-center tw-space-s-3.5 tw-mb-4"
							>
								<v-icon color="#D5D5D5"
									>mdi-office-building-outline</v-icon
								>
								<span class="tw-font-medium tw-text-black">{{
									$t('signUp.extraNumber')
								}}</span>
							</div>

							<v-text-field
								v-model="establishment.address.extraNumber"
								:placeholder="$t('signUp.extraNumberPlaceholder')"
								:rules="[rules.required($t('signUp.extraNumber'))]"
								outlined
								hide-details="auto"
								class="custom-outlined placeholder-padding tw-rounded-md"
							/>
						</div>
					</v-col>
					<v-col v-if="isCreate || isUpdate" cols="12">
						<BaseUpload
							v-model="establishment.address.proofOfAddress"
							title="signUp.addressAttachment"
							subtitle="signUp.attachmentSizeHint"
							@input="showAddressAttachError = false"
						/>
						<span
							v-if="showAddressAttachError"
							class="red--text tw-ms-2 tw-text-xs"
						>
							{{ $t('proof-national-address-required') }}
						</span>
					</v-col>
				</v-row>

				<template v-if="isView || isApprove">
					<h3
						class="tw-font-semibold tw-font-pop tw-text-19px tw-mt-4 lg:tw-mt-7 tw-mb-6 lg:tw-mb-12"
					>
						{{ $t('establishment.attachments') }}
					</h3>
					<BaseUpload
						v-model="attachments"
						:readonly="isReadonly"
						multiple
						actions-in-line
					/>
				</template>
			</v-form>

			<template v-if="isApprove">
				<h3
					class="tw-font-semibold tw-font-pop tw-text-19px tw-mt-4 lg:tw-mt-7 tw-mb-6 lg:tw-mb-12"
				>
					{{ $t('adminApproval.accreditationDecision') }}
				</h3>
				<v-form ref="approval" @submit.prevent="onValidate">
					<v-row>
						<v-col cols="6">
							<div>
								<div
									class="tw-flex tw-items-center tw-space-s-3.5 tw-mb-4"
								>
									<v-icon color="#D5D5D5">mdi-check</v-icon>
									<span class="tw-font-medium tw-text-black">
										{{ $t('adminApproval.apprvedStatus') }}
									</span>
								</div>
								<v-select
									v-model="approval.status"
									hide-details="auto"
									:items="statuses"
									:label="$t('adminApproval.apprvedStatus')"
									:placeholder="$t('adminApproval.apprvedStatus')"
									:rules="[rules.required($t('statusText'))]"
									:height="$vuetify.breakpoint.lgAndUp ? '60px' : ''"
									outlined
									single-line
									class="custom-outlined placeholder-padding tw-rounded-md"
								></v-select>
							</div>
						</v-col>
						<v-col v-if="approval.status != 'active'" cols="6">
							<div>
								<div
									class="tw-flex tw-items-center tw-space-s-3.5 tw-mb-4"
								>
									<v-icon color="#D5D5D5">mdi-check</v-icon>
									<span class="tw-font-medium tw-text-black">
										{{ $t('adminApproval.rejectedReason') }}
									</span>
								</div>
								<v-select
									hide-details="auto"
									v-model="approval.reason"
									:rules="[
										rules.required(
											$t('adminApproval.rejectedReason')
										),
									]"
									single-line
									class="custom-outlined placeholder-padding tw-rounded-md"
									:height="$vuetify.breakpoint.lgAndUp ? '60px' : ''"
									:placeholder="$t('adminApproval.rejectedReason')"
									:items="$reasonCategories.data"
									item-text="name"
									item-value="id"
									outlined
								></v-select>
							</div>
						</v-col>
						<v-col cols="12">
							<div
								class="tw-mb-4 tw-font-medium tw-flex tw-items-center tw-text-47"
							>
								{{ $t('adminApproval.sendNotes') }}
							</div>

							<v-textarea
								outlined
								rows="4"
								v-model="approval.notes"
								flat
								auto-grow
								class="custom-outlined tw-rounded-md"
								:placeholder="$t('adminApproval.sendNotes')"
								:rules="[rules.required($t('notes'))]"
								dense
							></v-textarea>
						</v-col>
					</v-row>
					<div class="tw-flex tw-w-full tw-space-s-9 tw-mt-2 tw-mb-4">
						<v-btn
							outlined
							color="primary"
							height="58"
							class="tw-flex-1 tw-rounded-lg"
							text
							@click="$router.back()"
						>
							<span
								class="tw-text-lg tw-font-medium tw-capitalize tw-text-72"
							>
								{{ $t('actions.cancel') }}
							</span>
						</v-btn>

						<v-btn
							:loading="loading"
							depressed
							height="58"
							type="submit"
							color="primary"
							class="tw-flex-1 tw-font-medium tw-rounded-lg"
						>
							<span
								class="tw-text-lg tw-font-medium tw-capitalize tw-text-white"
							>
								{{ $t('actions.confirm') }}
							</span>
						</v-btn>
					</div>
				</v-form>
			</template>
		</v-container>
	</div>
</template>

<script>
import moment from 'moment'

import { mapActions, mapGetters } from 'vuex'
import { hasHistory, omitEmpties, toFormData } from 'vuelpers'
import { createFormMixin } from '@/mixins/form-mixin'
import { toAttachment, getFile } from '@/helpers'

import UseFilePicker from '@/mixins/UseFilePicker'

export default {
	name: 'FormRegistrationRequest',
	mixins: [
		UseFilePicker,
		createFormMixin({
			rules: ['required', 'email', 'password'],
		}),
	],
	data() {
		return {
			establishment: {},
			success: null,
			menu: false,
			showCompanyAttachError: false,
			showAddressAttachError: false,
			showBankAttachError: false,
			showRepresentativeAttachment: false,
			loading: false,
			isCreating: false,
			isUpdating: false,
			isFormValid: false,
			dialogConfirmation: false,
			approval: {
				status: 'active',
				id: this.$route.params.id,
				reason: null,
				notes: '',
			},
			unsubscribePaste: () => {},
		}
	},
	watch: {
		'$route.path': {
			immediate: true,
			async handler() {
				this.establishment = this.initialEstablishment()

				this.getBank()
				this.getCategories({ per_page: 1000, type: this.type })

				if (this.isApprove) this.getReasonCategories()
				if (this.$route.path.includes('register')) return

				const id =
					this.$route.params.id ||
					this.$get(this.$currentUser, `${this.type}.0.id`)

				if (id) {
					this.$store.commit('settings/SET', { globalLoading: true })

					this.isBuyer
						? await this.initializeBuyer(id)
						: await this.initializeSupplier(id)

					this.$store.commit('settings/SET', { globalLoading: false })
				}
			},
		},
	},
	computed: {
		...mapGetters('bank', ['$banks']),
		...mapGetters('auth', ['$currentUser']),
		...mapGetters('buyers', ['$buyer']),
		...mapGetters('settings', ['$globalLoading']),
		...mapGetters('suppliers', ['$supplier']),
		...mapGetters('category', [
			'$supplierCategories',
			'$buyerCategories',
			'$reasonCategories',
		]),
		isCreate() {
			return this.$route.path.includes('/register')
		},
		isUpdate() {
			return this.$route.path.includes('/update')
		},
		isApprove() {
			return this.$route.path.includes('/approve')
		},
		isView() {
			return !(this.isUpdate || this.isApprove || this.isCreate)
		},
		isReadonly() {
			return (
				this.isView ||
				(this.isApprove && !this.isCreate) ||
				!this.$currentUser.isAdmin
				// !this.$currentUser.isOwner
			)
		},
		isBuyer() {
			return this.$currentUser.isBuyer || this.$route.path.includes('buyer')
		},
		isSupplier() {
			return (
				this.$currentUser.isSupplier ||
				this.$route.path.includes('supplier')
			)
		},
		name() {
			return this.isBuyer
				? this.$t('buyerLabel')
				: this.isSupplier
				? this.$t('supplierLabel')
				: ''
		},
		type() {
			return this.name.toLowerCase()
		},
		statuses() {
			return this.$t('adminApproval.approvedStatus').filter(status => {
				return status.value !== 'pending'
			})
		},
		categories() {
			return this.isBuyer
				? this.$buyerCategories.data
				: this.isSupplier
				? this.$supplierCategories.data
				: []
		},
		activity() {
			return this.categories
		},
		subActivity() {
			return (
				this.categories.find(category => {
					return (
						category.id === this.establishment.company.mainActivity?.id
					)
				})?.childCategories || []
			)
		},
		attachments() {
			return [
				this.toAttachment(
					this.establishment?.company?.proofOfRegistration,
					{
						category: this.$t('adminApproval.CompanyAttachment'),
					},
					true
				),
				this.toAttachment(
					this.establishment?.address.proofOfAddress,
					{
						category: this.$t('adminApproval.addressAttachment'),
					},
					true
				),
				this.toAttachment(
					this.establishment?.bank?.proofOfAccount,
					{
						category: this.$t('adminApproval.bankAttachment'),
					},
					true
				),
				this.toAttachment(
					this.establishment?.supplier?.representativeAttachment,
					{
						category: this.$t('adminApproval.representativeAttachment'),
					},
					true
				),
			]
		},
		hasAttachmentError() {
			return (
				this.showBankAttachError ||
				this.showCompanyAttachError ||
				this.showAddressAttachError ||
				this.showRepresentativeAttachment
			)
		},
	},
	methods: {
		...mapActions('bank', ['getBank']),
		...mapActions('auth', ['buyerRegistration', 'supplierRegistration']),
		...mapActions('category', ['getCategories', 'getReasonCategories']),
		...mapActions('buyers', [
			'getSingleBuyer',
			'buyerApprove',
			'updateBuyer',
		]),
		...mapActions('suppliers', [
			'getSingleSupplier',
			'supplierApprove',
			'updateSupplier',
		]),
		toAttachment,
		initialEstablishment(value = {}) {
			const bank = value?.bank || {}
			const address = this.$get(value, 'address.0', {})

			bank.accountNumber = (bank.accountNumber || '').toString()

			return {
				supplier: {
					id: value.id || null,
					status: value.status || '',
					notes: value.notes || '',
					isOwner: value?.isOwner ?? false,
					canBuyerSee: value?.canBuyerSee ?? false,
					representativeName: value.representativeName || '',
					representativeAttachment: this.toAttachment(
						this.$get(value, 'attachments.0', value.attachments)
					),
				},
				company: {
					id: value?.company?.id || null,
					name: value?.company?.name || '',
					registrationNumber: value?.company?.registrationNumber || '',
					recordExpDate: moment(value?.company?.recordExpDate).format(
						'YYYY-MM-DD'
					),
					storeName: value?.company?.storeName || '',
					email: value?.company?.email || '',
					phone: value?.company?.phone || null,
					mainActivity: value?.company?.mainActivity || null,
					subActivity: value?.company?.subActivity || null,
					proofOfRegistration: this.toAttachment(
						this.$get(value, 'company.proofOfRegistration')
					),
				},
				bank: {
					id: bank.id,
					bankId: bank.bankId || '',
					bankName: bank.bankName || '',
					accountName: bank.accountName || '',
					proofOfAccount: this.toAttachment(bank.proofOfAccount),
					accountNumber: bank.accountNumber.startsWith('SA')
						? bank.accountNumber.replace('SA', '')
						: bank.accountNumber || '',
				},
				address: {
					id: address.id,
					city: address.city,
					postalCode: address.postalCode,
					streetName: address.streetName,
					unitNumber: address.unitNumber,
					extraNumber: address.extraNumber,
					buildingNumber: address.buildingNumber,
					neighborhoodName: address.neighborhoodName,
					proofOfAddress: this.toAttachment(address.proofOfAddress),
				},
			}
		},
		onClickBack() {
			return hasHistory() ? this.$router.back() : this.$router.push('/')
		},
		async initializeBuyer(id = this.$route.params.id) {
			const [err, res] = await this.getSingleBuyer(id)
			if (err) {
				return this.$toast.error(
					err.message || `Error while fetching data. Please try again.`
				)
			}
			this.establishment = this.initialEstablishment(res.buyer)
		},
		async initializeSupplier(id = this.$route.params.id) {
			const [err, res] = await this.getSingleSupplier(id)
			if (err) {
				return this.$toast.error(
					err.message || `Error while fetching data. Please try again.`
				)
			}
			this.establishment = this.initialEstablishment(res.supplier)
		},
		getEstablishmentFormData() {
			return toFormData(
				{
					company: {
						...this.establishment.company,
						proofOfRegistration: null,
						subActivity: this.establishment.company.subActivity?.id,
						mainActivity: this.establishment.company.mainActivity?.id,
						recordExpDate: this.$m(
							this.establishment.company.recordExpDate
						).format(this.$t('yyyy-mm-dd-hh-mm-ss')),
						registrationAttachment: getFile(
							this.establishment.company.proofOfRegistration?.file
						),
					},
					address: {
						...this.establishment.address,
						proofOfAddress: null,
						attachment: getFile(
							this.establishment.address.proofOfAddress?.file
						),
					},
					bank: {
						...this.establishment.bank,
						proofOfAccount: null,
						accountNumber: 'SA' + this.establishment.bank.accountNumber,
						accountAttachment: getFile(
							this.establishment.bank.proofOfAccount?.file
						),
					},
					[this.name.toLowerCase()]: {
						...this.establishment.supplier,
						subCategoryId: this.establishment.company.subActivity?.id,
						mainCategoryId: this.establishment.company.mainActivity?.id,
						representativeAttachment: getFile(
							this.establishment.supplier.representativeAttachment?.file
						),
					},
					req_method: this.isUpdate ? 'PATCH' : 'POST',
				},
				{
					convertCase: 'snake_case',
				}
			)
		},
		async onClickCreateUpdate() {
			this.showBankAttachError = !this.establishment.bank.proofOfAccount
			this.showCompanyAttachError = !this.establishment.company
				.proofOfRegistration
			this.showAddressAttachError = !this.establishment.address
				.proofOfAddress
			this.showRepresentativeAttachment = !this.establishment.supplier
				.representativeAttachment

			if (!this.$refs.formRef.validate() || this.hasAttachmentError) {
				this.$utils.scrollIntoError(this)
				return
			}

			const onSubmit = async () => {
				const handler = this.isBuyer
					? this.isUpdate
						? this.updateBuyer
						: this.buyerRegistration
					: this.isUpdate
					? this.updateSupplier
					: this.supplierRegistration

				this.isCreating = true
				const [err] = await handler(this.getEstablishmentFormData())
				this.isCreating = false

				if (err) {
					this.$utils.scrollIntoError(this)
					return this.$toast.error(
						err.message ||
							`Error while ${
								this.isUpdate ? 'Updating' : 'registering'
							} ${this.name}`
					)
				}

				this.$toast.success(
					`${this.name} ${
						this.isUpdate ? 'Updated' : 'registered'
					} successfully`
				)

				if (this.$currentUser.isAdmin) {
					return this.$router.push({
						path: '/admin/management',
						query: {
							tab: this.isBuyer ? 'buyers' : 'suppliers',
							inner_tab: this.isBuyer
								? this.isUpdate
									? 'buyers'
									: 'approval'
								: this.isUpdate
								? 'suppliers'
								: 'supplier-pending-approvals',
						},
					})
				}
				this.$router.push(
					this.$currentUser.isSupplier
						? '/supplier/establishment'
						: '/establishment'
				)
			}

			if (this.isUpdate) {
				this.$root.confirmation = {
					handler: onSubmit,
					message: this.$t('sure-to-update-establishment'),
				}
				return
			}

			onSubmit()
		},
		onValidate() {
			if (!this.$refs.approval.validate()) {
				this.$utils.scrollIntoError(this)
				return
			}
			this.$root.confirmation = {
				handler: this.onSubmitApproved,
				message: this.$t(
					'are-you-sure-to-approve-this-registration-request',
					[this.statuses.find(v => v.value == this.approval.status).text]
				),
			}
		},
		async onSubmit() {
			const handler = this.isBuyer ? this.updateBuyer : this.updateSupplier
			const payload = omitEmpties(this.establishment)
			const [err, res] = await handler(payload)
			console.log({ err, res })
		},
		async onSubmitApproved() {
			this.confirmMsg =
				this.approval.status == 'active'
					? this.$t('buyer-registration-approved-successfully')
					: this.$t('request-declined-successfully')
			const payload = this.isBuyer
				? { buyer: this.approval }
				: { supplier: this.approval }
			const [err] = this.isBuyer
				? await this.buyerApprove(omitEmpties(payload))
				: await this.supplierApprove(omitEmpties(payload))
			if (err) {
				this.$toast.success(this.$t('an-unexpected-error-has-occured'))
			}
			//
			else {
				this.$toast.success(this.confirmMsg)
				this.$router.back()
			}
		},
	},
	created() {
		// if (this.isUpdate && !this.$currentUser.isOwner) {
		if (this.isUpdate && !this.$currentUser.isAdmin) {
			this.$toast.error(
				'You have to be a owner to update this informations.'
			)
			this.onClickBack()
		}
	},
	mounted() {
		this.unsubscribePaste = this.$utils.useDocumentPaste(
			[
				'company.proofOfRegistration',
				'bank.proofOfAccount',
				'supplier.representativeAttachment',
				'address.proofOfAddress',
			],
			this.establishment,
			v => v
		)
	},
	beforeDestroy() {
		this.unsubscribePaste()
	},
}
</script>

<style lang="scss">
.attachment-table {
	.v-data-table {
		.v-data-table__wrapper {
			tbody > tr > td {
				font-size: 14px;
				color: #000 !important;
				font-weight: 600 !important;
				border-bottom: 1px solid #f2f2f2 !important;
			}
		}
	}
}
</style>
